import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map, tap, catchError } from 'rxjs/operators';
import Constants from '../commonFiles/constant/Constants';
import { CognitoUtil, CognitoCallback } from '../auth/conginto.service';
import { SpinnerService } from '../commonFiles/services/spinner.service';
import { TranslateService } from '../commonFiles/services/translate.service';
import { HttpClient } from '@angular/common/http';
import { BaseLayerService } from '../commonFiles/services/BaseLayerService'
import { AppConfigService } from '../commonFiles/services/app-initializer.service';
import * as JWT from 'jwt-decode';
import { environment } from '../../environments/environment';
import { ErrorService } from '../commonFiles/services/error.service';
import { fromPromise } from 'rxjs/observable/fromPromise';
import Amplify, { Auth } from 'aws-amplify';
import { of } from 'rxjs';
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { AppResponse } from '../groupsettings/group.models';
import { AdminService } from './admin.service';
export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
  confirmPassword:string;
}

@Injectable()
export class AuthService extends BaseLayerService {
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loginState = false
  isfirstlogin = false;
  userPoolId
  logoutTimer = null;
  constructor(
    public router: Router,
    protected translate: TranslateService,
    protected spinner: SpinnerService,
    protected cognitoUtil: CognitoUtil,
    private appconfig: AppConfigService,
    private adminService: AdminService,
    protected http: HttpClient,
    public errorService: ErrorService,
  ) {
    super(errorService, environment.AWSINFO.AuthApiBaseurl, http, spinner, router, appconfig);
    this.loggedIn = new BehaviorSubject<boolean>(false);
    var isamplify ={};
    if (window.location.hostname.indexOf('dev') !== -1 || window.location.hostname == 'localhost'){
      
   // if (window.location.hostname.indexOf('dev') !== -1){

   
    isamplify = {
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_07555YYh7',
      userPoolWebClientId: this.userPoolId = '617ljkn136n52q7n5qocmsi4rp',

    }; 
    
   /*    
     
       


     isamplify = {
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_w3fInVJ3w',
      userPoolWebClientId: this.userPoolId = '20hafrghkp1v70i11d9ka2mof2',
    };
     isamplify = {
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_w3fInVJ3w',
      userPoolWebClientId: this.userPoolId = '20hafrghkp1v70i11d9ka2mof2',
    };
    */
    }
    else if ( window.location.hostname.indexOf('test') !== -1 )
   // else if ( window.location.hostname.indexOf('test') !== -1 || window.location.hostname == 'localhost')
   {
     isamplify = {
      region: 'ap-south-1',
      userPoolId: 'ap-south-1_5ZGVtosC5',
      userPoolWebClientId: this.userPoolId = '9s8upm80gnvcaokban7cimun8',
    };
   }
    else
    {
       isamplify = {
        region: 'ap-south-1',
        userPoolId: 'ap-south-1_w3fInVJ3w',
        userPoolWebClientId: this.userPoolId = '20hafrghkp1v70i11d9ka2mof2',
      };
    }
    this.initLogoutTimer();
    Amplify.configure(isamplify);
  }

  get isLoggedIn() {
    if (localStorage.getItem(Constants.STORAGE.TOKEN) != null) {
      this.loggedIn.next(true);
      this.loginState = true;
    }
    return this.loggedIn.asObservable();
  }

  initLogoutTimer() {
    if (this.logoutTimer) {
      clearInterval(this.logoutTimer);
    }
    this.logoutTimer = setInterval(() => {
      this.checkAutoLogout();
    }, 500);
  }

  signIn(email, password): Observable<any> {
    return fromPromise(Auth.signIn(email, password))
      .pipe(
        tap(result => {          
          this.loginState = true;
          if (result.challengeName != "NEW_PASSWORD_REQUIRED") {
            this.loggedIn.next(true);
            localStorage.setItem(Constants.STORAGE.TERMS, "false");
            this.SetUserOutput(result);
          }
          else if (result.challengeName == "NEW_PASSWORD_REQUIRED") {
            localStorage.setItem("temp", password)
            localStorage.setItem(Constants.STORAGE.TERMS, "true");
            localStorage.setItem(Constants.STORAGE.ROLE, result.challengeParam.userAttributes[Constants.UserInfo.ATTRIBUTES_ACCESSRIGHTS]);
          }
        },
          error => {
            this.errorService.setResponsePostMessage(error.message);
            if (error.code == "PasswordResetRequiredException") {
              this.router.navigate(['/confirm-password/' + email]);
            }
          }));
  }
  SetUserOutput(result) {
    var idToken = result.storage["CognitoIdentityServiceProvider." + this.userPoolId + "." + result.username + ".idToken"];
    var token = result.storage["CognitoIdentityServiceProvider." + this.userPoolId + "." + result.username + ".accessToken"];
    var refreshToken = result.storage["CognitoIdentityServiceProvider." + this.userPoolId + "." + result.username + ".refreshToken"];
    localStorage.setItem(Constants.STORAGE.REFRESHTOKEN, refreshToken);
    localStorage.setItem(Constants.STORAGE.TOKEN, token);
    localStorage.setItem(Constants.STORAGE.IDTOKEN, idToken);
    localStorage.setItem(Constants.STORAGE.EXPIRED_TIME, result.signInUserSession.idToken.payload.exp);
    sessionStorage.setItem(Constants.STORAGE.IDTOKEN, idToken);
    localStorage.setItem("isSD", "false")
    localStorage.setItem("isEdl", "false")
    localStorage.setItem("isEol", "false")
    //TODO:// Admin user need to check condition
    var decodedtoken = JWT(idToken);
    if ((decodedtoken['given_name'] != null)) {
      localStorage.setItem(Constants.UserInfo.USERNAME, decodedtoken['given_name'] + ' ' + decodedtoken['family_name']);
      this.errorService.setResponsePostMessage(true);
    }
    if (decodedtoken["custom:company"] != null)
      localStorage.setItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME, decodedtoken["custom:company"]);

    if (decodedtoken["custom:businessunit"] != null)
      localStorage.setItem(Constants.UserInfo.BUSINESS_UNIT, decodedtoken["custom:businessunit"]);

    if (decodedtoken["cognito:groups"] != undefined)
      localStorage.setItem(Constants.STORAGE.BUCKET, decodedtoken["cognito:groups"][0]);

       
    if (decodedtoken["cognito:edlvalidity"] != '' ||decodedtoken["cognito:edlvalidity"] != undefined && decodedtoken["custom:edlvalidity"].length >= 10) {
      localStorage.setItem("EDLValidity",decodedtoken["custom:edlvalidity"]);
    }
    if (decodedtoken["cognito:eolvalidity"] != '' || decodedtoken["cognito:eolvalidity"] != undefined && decodedtoken["custom:eolvalidity"].length >= 10) {
      localStorage.setItem("EOLValidity",decodedtoken["custom:eolvalidity"]);
    }
    if (decodedtoken["cognito:sdvalidity"] != '' || decodedtoken["cognito:sdvalidity"] != undefined &&decodedtoken["custom:sdvalidity"].length >= 10) {
      localStorage.setItem("SDValidity",decodedtoken["custom:sdvalidity"]);
    }
     
  }

 
  getEDlEnable() {    
    var decodedtoken = JWT(localStorage.getItem(Constants.STORAGE.IDTOKEN));
    var isEnable = false;
    var isValidy = false;
    var isWeb = false;
    
    if (decodedtoken["custom:edlenable"] != null) {
      isEnable = decodedtoken["custom:edlenable"] === "True" ? true : false;
    }    
    if (decodedtoken["custom:edlvalidity"] != null) {
      if (decodedtoken["custom:edlvalidity"].length >= 10) {       
        const unixEpochTimeMS = decodedtoken["custom:edlvalidity"] * 1000;
        const d = new Date(unixEpochTimeMS);
        var validy = this.compareDate(d, new Date())
        if (validy == 1)
          isValidy = true;
      }
      else {     
        this.adminService.updateValidyEdl(decodedtoken["custom:edlvalidity"]).subscribe(res => {
        })        
        isValidy = true;        
      }
    }
    if (decodedtoken["custom:edlweb"] != null) {
      isWeb = decodedtoken["custom:edlweb"] === "True" ? true : false;
    }
    localStorage.setItem("edlAdmin", decodedtoken["custom:edladmin"] === "True" ? "true" : "false")
    
    if (isEnable && isValidy && isWeb) {
      localStorage.setItem(Constants.UserInfo.EDL, "true");
      return true;
    }
    localStorage.setItem(Constants.UserInfo.EDL, "false");
    return false;
  }

  getEOlEnable() {
    var Eoltoken = JWT(localStorage.getItem(Constants.STORAGE.IDTOKEN));
    var isEnable = false;
    var isValidy = false;
    var isWeb = false;
    if (Eoltoken["custom:eolenable"] != null) {
      isEnable = Eoltoken["custom:eolenable"] === "True" ? true : false;
    }
    if (Eoltoken["custom:eolvalidity"] != null) {  
      if (Eoltoken["custom:eolvalidity"].length >= 10) {
          const unixEpochTimeMS = Eoltoken["custom:eolvalidity"] * 1000;
          const d = new Date(unixEpochTimeMS);
          var validy = this.compareDate(d, new Date())
        if (validy == 1)
          isValidy = true;
      }
      else {        
        this.adminService.updateValidyEol(Eoltoken["custom:eolvalidity"]).subscribe(res => {
        })
        isValidy = true;        
      }  
    }
    if (Eoltoken["custom:eolweb"] != null) {
      isWeb = Eoltoken["custom:eolweb"] === "True" ? true : false;
    }
    localStorage.setItem("eolAdmin", Eoltoken["custom:eoladmin"] === "True" ? "true" : "false")
    if (isEnable && isValidy && isWeb) {
      localStorage.setItem(Constants.UserInfo.EOL, "true");
      return true;
    }
    localStorage.setItem(Constants.UserInfo.EOL, "false");
    return false;
  }
  getSDEnable() {
    var sdtoken = JWT(localStorage.getItem(Constants.STORAGE.IDTOKEN));
    var isEnable = false;
    var isValidy = false;
    var isWeb = false;
    if (sdtoken["custom:sdenable"] != null) {
      isEnable = sdtoken["custom:sdenable"] === "True" ? true : false;
    }
    
    if (sdtoken["custom:sdvalidity"] != null) {            
      if (sdtoken["custom:sdvalidity"].length >= 10) {        
        const unixEpochTimeMS = sdtoken["custom:sdvalidity"] * 1000;        
        const d = new Date(unixEpochTimeMS);
        var validy = this.compareDate(d, new Date())
        if (validy == 1)
          isValidy = true;
      } else {        
        this.adminService.updateValidySd(sdtoken["custom:sdvalidity"]).subscribe(res => {
        })
        isValidy = true;        
      }
     
    }
    if (sdtoken["custom:sdweb"] != null) {
      isWeb = sdtoken["custom:sdweb"] === "True" ? true : false;
    }
    localStorage.setItem("sdAdmin", sdtoken["custom:sdadmin"] === "True" ? "true" : "false")
    if (isEnable && isValidy && isWeb) {
      localStorage.setItem(Constants.UserInfo.SD, "true");
      return true;
    }
    localStorage.setItem(Constants.UserInfo.SD, "false");
    return false;
  }

  getAuthToken() {
    return localStorage.getItem(Constants.STORAGE.IDTOKEN);
  }

  public RefreshToken() {
    

    this.checkAutoLogout();
  }
  
  async checkAutoLogout() {
    if (this.loginState) {
      if(localStorage.getItem("expired_time")===undefined)
      {       
        this.signOut();
      }
     
      var getDate =Number.parseInt(localStorage.getItem("expired_time")) *1000;
      let expiryTime = new Date(getDate);
       
      let dateNow = new Date();
      if (expiryTime < dateNow)
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const { refreshToken } = cognitoUser.getSignInUserSession();
          cognitoUser.refreshSession(refreshToken, (err, session) => {
           
            localStorage.setItem(Constants.STORAGE.EXPIRED_TIME, session.accessToken.payload.exp);
            localStorage.setItem(Constants.STORAGE.IDTOKEN, session.idToken.jwtToken);
            localStorage.setItem(Constants.STORAGE.TOKEN, session.accessToken.jwtToken);
          });
        } catch (e) {
          if(e=="not authenticated")
         {
          this.signOut();
          console.log('Unable to refresh Token', e);}
        }
      else {
        this.appConfig.SessionTimer = this.getTimeDiffString(dateNow, expiryTime);
      }

      if(Date.parse(localStorage.getItem("LastActiveTime")) * 1000 <= Date.parse(dateNow.toString())* 1000)
      {       
        this.signOut();
      }
    }
  }

  getTimeDiffString(dateSrc1: Date, dateSrc2: Date) {
    var date1 = dateSrc1.getTime();
    var date2 = dateSrc2.getTime();
    var duration = date2 - date1;
    var seconds = (duration / 1000);
    var minutes = parseInt((seconds / 60).toString(), 10);
    seconds = seconds % 60;
    var hours = parseInt((minutes / 60).toString(), 10);
    minutes = minutes % 60;
    var nonMsSeconds = parseInt(seconds.toString(), 10);
    if (hours > 0)
      return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + nonMsSeconds.toString().padStart(2, '0');
    else if (minutes > 0)
      return minutes.toString().padStart(2, '0') + ':' + nonMsSeconds.toString().padStart(2, '0');
    else
      return nonMsSeconds.toString().padStart(2, '0');
  }
  
  public signOut() {
    
    this.loggedIn.next(false);
    localStorage.clear();
  
    this.loginState = false;
    Auth.signOut();
    this.router.navigate(['login']);
  }

  setLangdefault() {
    localStorage.setItem(Constants.STORAGE.LANG, `en`);
    this.translate.active('en');
    this.translate.activeLangChanged.emit();
  }

  gethostname(url) {
    if (window.location.hostname == 'localhost')
      return StringFormat(url, 'dev');
    else if (window.location.hostname.indexOf('dev') !== -1)
      return StringFormat(url, 'dev');
    else if (window.location.hostname.indexOf('test') !== -1)
      return StringFormat(url, 'test');
    else
      return StringFormat(url, 'prod');
  }

  public forgetPasswordEmail(email): Observable<any> {
    return fromPromise(Auth.forgotPassword(email))
      .pipe(
        map(result => {
          if (result.CodeDeliveryDetails != undefined) {
            var appResponse: AppResponse = <any>{};
            appResponse.isOk = true;
            return appResponse;
          }
        }),
        catchError(error => {
          var appResponse: AppResponse = <any>{};
          appResponse.isOk = false;
          appResponse.message = error.message;
          return of(appResponse);
        })
      );
  }
  
  public resetPassword(username, code, password): Observable<any> {
    return fromPromise(Auth.forgotPasswordSubmit(username, code, password))
      .pipe(
        map(result => {
          var appResponse: AppResponse = <any>{};
          appResponse.isOk = true;
          return appResponse;
        }),
        catchError(error => {
          var appResponse: AppResponse = <any>{};
          appResponse.isOk = false;
          appResponse.message = error.message;
          return of(appResponse);
        })
      );
  }

  public changePassword(oldPassword, newPassword): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser().then(session => {
        session.changePassword(
          oldPassword,
          newPassword,
          (err, data) => {
            if (err) {
              var appResponse: AppResponse = <any>{};
              appResponse.isOk = false;
              appResponse.message = err.message;
              return resolve(appResponse);
            } else {
              var appResponseFailed: AppResponse = <any>{};
              appResponseFailed.isOk = true;
              return resolve(appResponseFailed);
            }
          },
        )
      })
    });
  }

  SetNewPasswordPassword(newPasswordUser: NewPasswordUser): Observable<any> {
    let authenticationData = {
      Username: newPasswordUser.username,
      Password: newPasswordUser.existingPassword,
    };

    let authenticationDetails = new AuthenticationDetails(authenticationData);
    let userData = {
      Username: newPasswordUser.username,
      Pool: this.cognitoUtil.getUserPool()
    };
    let cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (UserAttributes, requiredAttributes) {
        delete UserAttributes.email_verified;
        cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
          onSuccess: function (result) {
            return result;            
          },
          onFailure: function (err) {      
            return err;
          }
        });
      },
      onSuccess: function (result) {        
        return result;
      },
      onFailure: function (err) {
        return err;        
      }
    });
    return null;
  }
  SetScreenNewPassword(newPasswordUser, callback: CognitoCallback) {
    let username = localStorage.getItem(Constants.STORAGE.EMAIL_ADDRESS);
    let authenticationData = {
      Username: username,
      Password: newPasswordUser.oldPassword,
    };

    let authenticationDetails = new AuthenticationDetails(authenticationData);
    let userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    let cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: function (UserAttributes, requiredAttributes) {
        UserAttributes.name = username;
        cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
          onSuccess: function (result) {
            //this.SetUserOutput(result);
            
            callback.cognitoCallback(null, result);
          },
          onFailure: function (err) {
            callback.cognitoCallback(err.message, null);
          }
        });
      },
      onSuccess: function (result) {
        callback.cognitoCallback(null, result);
      },
      onFailure: function (err) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }

  compareDate(date1: Date, date2: Date): number {
    let d1 = new Date(date1); let d2 = new Date(date2);
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;
    if (d1 > d2) return 1;
    if (d1 < d2) return -1;
  }

  checkSuperAdmin() {
    if (localStorage.getItem(Constants.UserInfo.ATTRIBUTES_COMPANYNAME) === 'bosch' && localStorage.getItem(Constants.UserInfo.BUSINESS_UNIT) === 'superadmin') {
      return true;
    }
    else {
      return false;
    }
  }

  validateUser(){
    
    var decodedtoken =JWT(localStorage.getItem(Constants.STORAGE.IDTOKEN));
     if(localStorage.getItem("companyname") !=undefined && localStorage.getItem("companyname") != decodedtoken["custom:company"])
     {
      this.logOut();
     }
     
     if(localStorage.getItem("businessunit") !=undefined &&  localStorage.getItem("businessunit")!= decodedtoken["custom:businessunit"])
     {
      this.logOut();
     }
     if(localStorage.getItem("edlAdmin") !=undefined &&  localStorage.getItem("edlAdmin").toLowerCase()!= decodedtoken["custom:edladmin"].toLowerCase())
     {
      this.logOut();
     }
     if(localStorage.getItem("eolAdmin") !=undefined &&  localStorage.getItem("eolAdmin").toLowerCase()!= decodedtoken["custom:eoladmin"].toLowerCase())
     {
      this.logOut();
     }
     if(localStorage.getItem("sdAdmin") !=undefined &&  localStorage.getItem("sdAdmin").toLowerCase()!= decodedtoken["custom:sdadmin"].toLowerCase())
     {
      this.logOut();
     }
  }

  logOut(){
    localStorage.clear();
    Auth.signOut();
    this.loginState =false;
    this.router.navigate(['/login']);
  }
}

const StringFormat = (str: string, ...args: string[]) =>
  str.replace(/{(\d+)}/g, (match, index) => args[index] || '')