// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: true,
  
  translateLang: [
    {
        "shortlang": "jn",
        "language": "japanish"
    },
    {
        "shortlang": "en",
        "language": "English"
    }
],
istranslate: true,
PasswordPattern:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{8,}$/,
AWSINFO: {
  "identityPoolId": "ap-south-1:52416c97-c403-404a-a670-b2f72a68a39c",
  "region": "ap-south-1",
  "userPoolId": "ap-south-1_7dTTnEmQU",
  "userPoolWebClientId": "2gv0ns1477rj392dbd7itadj74",
  "accessKeyId": "",
  "secretAccessKey": "",
  
  /* "accessKeyId": "AKIAZTHGECYUJDPZSENE",
  "secretAccessKey": "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", */
  "rekognitionBucket": "sventa-edl-data-{0}", 
  "EOLjsonfileSourceFolder": "EOL/ProcessedData",
  "EDLjsonfileSourceFolder": "{0}/ProcessedData",
  "sessionToken":"",
  "AuthApiBaseurl": "https://api.sventa.io/{0}/authorize",
  "Userurl": "https://api.sventa.io/{0}/authenticated",
  "BaseURL": "https://api.sventa.io/",
  "AuthorizeAPI":"authorize",
  "AuthenticatedAPI":"authenticated",
  "AppName":'sventa',
  "downloadRawFilePath": "Downloads/",
  "WaterfallColormapfile": "Waterfall_Colormap.json"

  },
  "APPINFO": {
    "FFTfilename": "fftavg.gz",
    "overfilename": "overalllevel.gz",
    "waterfilename": "waterfall.gz",
    "rawfilename": "raw_data.raw",
    "DataNameFFT": "FFT",
    "DataNameRawdata": "Rawdata",
    "Octavefilename": "octave.gz",
    "ThirdOctavefilename": "thirdoctave.gz",
    "UserFile": "user.gz",
    "AudioFile": "audio.mp3",
    "AudioFile1": "audio.wav",
    "DownloadRawurl": "raw_data.csv",
    "Downloadutf": "raw_data.uff",
    "PRTFileName": "prvstime.gz",
    "PRAvgFileName": "pravg.gz",
  },
"APPINFOjson": {
  "FFTfilename": "fftavg.json",
  "overfilename": "overalllevel.json",
  "waterfilename": "waterfall.json",
  "rawfilename": "raw_data.raw",
  "DataNameFFT": "FFT",
  "DataNameRawdata": "Rawdata",
  "Octavefilename":"octave.json",
  "ThirdOctavefilename":"thirdoctave.json",
   "UserFile":"user.json",
  "AudioFile": "audio.mp3",
  "AudioFile1": "audio.wav",
  "DownloadRawurl":"raw_data.csv",
  "Downloadutf":"raw_data.uff",
  "PRTFileName": "prvstime.json",
  "PRAvgFileName": "pravg.json",
},

RAW_FILES : [
  {
    title: 'RAW DATA CSV',
    fileName: 'raw_data.csv.gz',
    fileType: 'text/csv'
  },
  {
    title: 'RAW DATA UFF',
    fileName: 'raw_data.uff',
    fileType: 'text/plain'
  },
  {
    title: 'AUDIO',
    fileName: 'audio.mp3',
    fileType: 'audio/mp3'
  },
],

PROCESSED_FILES : [
  {
    title: 'FFT VS TIME',
    fileName: 'waterfall.csv.gz',
    fileType: 'application/gzip'
  },
  {
    title: 'FFT AVG',
    fileName: 'fftavg.csv.gz',
    fileType: 'application/gzip'
  },
  {
    title: 'OCTAVE',
    fileName: 'octave.csv.gz',
    fileType: 'application/gzip'
  },
  {
    title: 'OVERALL LEVEL',
    fileName: 'overalllevel.csv.gz',
    fileType: 'application/gzip'
  },
  {
    title: 'PR VS TIME',
    fileName: 'prvstime.csv.gz',
    fileType: 'application/gzip'
  },
  {
    title: 'THIRD OCTAVE',
    fileName: 'thirdoctave.csv.gz',
    fileType: 'application/gzip'
  },
  {
    title: 'PR AVG',
    fileName: 'pravg.csv.gz',
    fileType: 'application/gzip'
  },
],

FALLBACK_FILE_LIST: {
  'raw_data.csv.gz': 'raw_data.csv'
},

};
