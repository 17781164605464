import { Injectable } from "@angular/core";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { AWSError } from "aws-sdk/global";
import { ListUsersInGroupResponse } from "aws-sdk/clients/cognitoidentityserviceprovider";


export interface CognitoCallback {
    cognitoCallback(message: string, result: any): void;
    cognitoCallbackRessendCode?(message: string, result: any): void;
    handleMFAStep?(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void;
   
}
export interface GetGroupCallback{
    GetGroupCallback?(userList: ListUsersInGroupResponse, Groups:string): void;
    GetUserListCallback?(message: AWSError, result: any): void;
}
export interface InviteUserCallback
{
    inviteUserCallback?(message:AWSError,result:any)
}
export interface NewPasswordCallback{
    cognitoNewpasswordCallback?(message: string);
    cognitoCallbackRessendCode?(message: string, result: any): void;
    ChangePswdCallback?(message: string, result: any);
}
export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface ChallengeParameters {
    CODE_DELIVERY_DELIVERY_MEDIUM: string;

    CODE_DELIVERY_DESTINATION: string;
}

export interface Callback {
    callback(): void;

    callbackWithParam(result: any): void;
}

@Injectable()
export class CognitoUtil {

    public static _REGION =""

    public static _IDENTITY_POOL_ID =""// environment.identityPoolId;
    public static _USER_POOL_ID = "";//environment.userPoolId;
    public static _CLIENT_ID =""// environment.clientId;

    public static _DEV_POOL_DATA: any = {
        UserPoolId: 'ap-south-1_07555YYh7',
        ClientId: '617ljkn136n52q7n5qocmsi4rp'
    };
    public static _TEST_POOL_DATA: any = {
        UserPoolId: 'ap-south-1_5ZGVtosC5',
        ClientId: '9s8upm80gnvcaokban7cimun8'
    };
    public static _POOL_DATA: any = {
        UserPoolId: 'ap-south-1_w3fInVJ3w',
        ClientId: '20hafrghkp1v70i11d9ka2mof2'
    };
    constructor(){
        

    }
    public cognitoCreds: AWS.CognitoIdentityCredentials;

    getUserPool() {             
         if ( window.location.hostname.indexOf('dev') !== -1 || window.location.hostname == 'localhost'){
        return new CognitoUserPool(CognitoUtil._DEV_POOL_DATA);}
        else if ( window.location.hostname.indexOf('test') !== -1 || window.location.hostname == 'localhost'){
        return new CognitoUserPool(CognitoUtil._TEST_POOL_DATA);}
        else{
        return new CognitoUserPool(CognitoUtil._POOL_DATA);}
    }

    getCurrentUser() {
        return this.getUserPool().getCurrentUser();
    }
    
}